<template>
  <div class="claim-success-animation" @click="$emit('close')">
    <div class="celebration-container">
      <div class="confetti-explosion"></div>
      <h2 class="text-4xl font-bold text-green-400 mb-4">Congratulations! 🎉</h2>
      <p class="text-2xl text-white mb-8">
        You've claimed your rewards!
      </p>
      <p class="text-gray-400">Your rewards have been sent to your wallet</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ClaimSuccessAnimation',
  emits: ['close']
}
</script>

<style scoped>
.claim-success-animation {
  @apply fixed inset-0 flex items-center justify-center z-50;
  background: rgba(0, 0, 0, 0.8);
}

.celebration-container {
  @apply text-center p-8 rounded-xl;
  animation: fadeIn 0.5s ease-out;
}

.confetti-explosion {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  pointer-events: none;
}

@keyframes fadeIn {
  from { opacity: 0; transform: scale(0.9); }
  to { opacity: 1; transform: scale(1); }
}
</style>
