import contractConfig from "./contractConfig";

const chainConfig = {
  local: {
    id: 31337,
    name: "local",
    network: "hardhat",
    nativeCurrency: {
      decimals: 18,
      name: "Ethereum",
      symbol: "ETH",
    },
    contracts: contractConfig.local,
    rpcUrls: {
      default: {
        http: ["http://127.0.0.1:8545"],
        webSocket: ["ws://127.0.0.1:8545"],
      },
      public: {
        http: ["http://127.0.0.1:8545"],
        webSocket: ["ws://127.0.0.1:8545"],
      },
    },
    blockExplorers: {
      default: {
        name: "Local Explorer",
        url: "", // You can leave this empty for local development
      },
    },
  },
  testnet_arb: {
    id: 421614,
    name: "Arbitrum Sepolia",
    network: "Arbitrum Sepolia",
    nativeCurrency: {
      decimals: 18,
      name: "ETH",
      symbol: "ETH",
    },
    contracts: contractConfig.testnetArb,
    multicall3: {
      address: "0xA115146782b7143fAdB3065D86eACB54c169d092",
      blockCreated: 136,
    },
    rpcUrls: {
      default: {
        http: [
          "https://arbitrum-sepolia.infura.io/v3/2c4940564c594120b44f5e9e660bc6ad",
        ],
        webSocket: [""],
      },
      public: {
        http: [
          "https://arbitrum-sepolia.infura.io/v3/2c4940564c594120b44f5e9e660bc6ad",
        ],
        webSocket: [""],
      },
    },
    blockExplorers: {
      etherscan: {
        name: "Arbitrum Sepolia",
        url: "https://sepolia.arbiscan.io/",
      },
      default: {
        name: "Arbitrum Sepolia",
        url: "https://sepolia.arbiscan.io/",
      },
    },
  },
  mainnet_arb: {
    id: 42161,
    name: "Arbitrum",
    network: "Arbitrum",
    nativeCurrency: {
      decimals: 18,
      name: "ETH",
      symbol: "ETH",
    },
    contracts: contractConfig.mainnet_arb,
    multicall3: {
      address: "0x842eC2c7D803033Edf55E478F461FC547Bc54EB2",
      blockCreated: 821923,
    },
    rpcUrls: {
      default: {
        http: ["https://arb1.arbitrum.io/rpc"],
        webSocket: [""],
      },
      public: {
        http: ["https://arb1.arbitrum.io/rpc"],
        webSocket: [""],
      },
    },
    blockExplorers: {
      etherscan: {
        name: "Arbiscan",
        url: "https://arbiscan.io/",
      },
      default: {
        name: "Arbiscan",
        url: "https://arbiscan.io/",
      },
    },
  },
  blastSepolia: {
    id: 168587773,
    name: "Blast Sepolia",
    network: "Blast Sepolia",
    nativeCurrency: {
      decimals: 18,
      name: "ETH",
      symbol: "ETH",
    },
    contracts: contractConfig.blastSepolia,
    rpcUrls: {
      default: {
        http: [
          "https://blast-sepolia.infura.io/v3/2c4940564c594120b44f5e9e660bc6ad",
        ],
        webSocket: [""],
      },
      public: {
        http: [
          "https://blast-sepolia.infura.io/v3/2c4940564c594120b44f5e9e660bc6ad",
        ],
        webSocket: [""],
      },
    },
    blockExplorers: {
      default: {
        name: "Blastscan",
        url: "https://sepolia.blastexplorer.io/",
      },
    },
  },
  blastMainnet: {
    id: 81457,
    name: "Blast Mainnet",
    network: "Blast Mainnet",
    nativeCurrency: {
      decimals: 18,
      name: "ETH",
      symbol: "ETH",
    },
    contracts: contractConfig.blastMainnet,
    rpcUrls: {
      default: {
        http: [
          "https://blast-mainnet.g.alchemy.com/v2/28FQbIaZm5Dg5yWgRhRiiUJl4AdlNN2A",
        ],
        webSocket: [""],
      },
      public: {
        http: [
          "https://blast-mainnet.g.alchemy.com/v2/28FQbIaZm5Dg5yWgRhRiiUJl4AdlNN2A",
        ],
        webSocket: [""],
      },
    },
    blockExplorers: {
      default: {
        name: "Blastscan",
        url: "https://blast-mainnet.g.alchemy.com/v2/28FQbIaZm5Dg5yWgRhRiiUJl4AdlNN2A",
      },
    },
  },
};

export default chainConfig;
