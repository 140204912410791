<template>
  <div class="text-center">
    <div class="relative flex items-center justify-center">
      <h1 class="text-xl font-sans text-white p-5 text-center">
        Your Prize Share
      </h1>
    </div>
    <div v-if="isLoading" class="loading-container">
      <span class="loading-text">Loading</span>
    </div>
    <div v-else class="flex justify-center mb-5">
      <div class="relative w-48 h-48">
        <svg viewBox="0 0 100 100" class="w-full h-full">
          <defs>
            <linearGradient id="allocationGradient" x1="0%" y1="0%" x2="100%" y2="0%">
              <stop offset="0%" style="stop-color: #22c55e" />
              <stop offset="50%" style="stop-color: #16a34a" />
              <stop offset="100%" style="stop-color: #22c55e" />
            </linearGradient>
            <linearGradient id="zeroGradient" x1="0%" y1="0%" x2="100%" y2="0%">
              <stop offset="0%" style="stop-color: #ef4444" />
              <stop offset="50%" style="stop-color: #dc2626" />
              <stop offset="100%" style="stop-color: #ef4444" />
            </linearGradient>
            <filter id="allocationGlow">
              <feGaussianBlur stdDeviation="2" result="coloredBlur"/>
              <feMerge>
                <feMergeNode in="coloredBlur"/>
                <feMergeNode in="SourceGraphic"/>
              </feMerge>
            </filter>
          </defs>
          <!-- Background circle -->
          <circle
            r="35"
            cx="50"
            cy="50"
            fill="transparent"
            stroke="#1F2937"
            stroke-width="8"
            class="opacity-30"
          />
          <!-- Progress circle with gradient -->
          <circle
            r="35"
            cx="50"
            cy="50"
            fill="transparent"
            :stroke-dasharray="`${calculateProgress} ${fullCircle}`"
            :stroke="displayedAllocation > 0 ? 'url(#allocationGradient)' : 'url(#zeroGradient)'"
            stroke-width="8"
            class="transition-all duration-500"
            transform="rotate(-90 50 50)"
            filter="url(#allocationGlow)"
          />
        </svg>
        <!-- Centered text -->
        <div class="absolute inset-0 flex flex-col items-center justify-center">
          <span class="text-base font-bold text-white" :class="displayedAllocation > 0 ? 'text-green-400' : 'text-red-400'">
            {{ Number(displayedAllocation).toFixed(2) }}
          </span>
          <span class="text-sm font-bold text-gray-400">$Flappy</span>
          <span v-if="gameState == 1" class="text-xs text-gray-500">(Estimated)</span>
        </div>
      </div>
    </div>
    <div v-if="gameState == 2 && displayedAllocation > 0" 
         class="flex justify-center mb-5">
      <button 
        @click="claimAllocation"
        class="bg-gradient-to-r from-green-500 via-green-400 to-green-500 
               text-white font-bold py-2 px-4 rounded-lg
               hover:opacity-90 transition-all duration-300
               animate-pulse">
        Claim
      </button>
    </div>
  </div>
</template>

<script>
import { ref, computed, watch } from "vue";
import { useStore } from "vuex";
import apiService from "../assets/js/apiService";
import { useToast } from "vue-toastification";

export default {
  name: "AirdropAllocation",
  setup() {
    const toast = useToast();
    const store = useStore();
    const isLoading = ref(true);
    const estimatedAllocation = ref(0);

    const gameState = computed(() => store.state.gameState);
    const score = computed(() => store.state.highestScore);
    const prizePool = computed(() => store.state.prizePool);
    const storeAllocation = computed(() => parseFloat(store.state.allocation));

    const displayedAllocation = computed(() => {
      return storeAllocation.value > 0
        ? storeAllocation.value
        : estimatedAllocation.value;
    });

    const fullCircle = computed(() => 2 * Math.PI * 35);
    const calculateProgress = computed(() => {
      if (!displayedAllocation.value || !prizePool.value) return 0;
      
      // Calculate the percentage of the prize pool
      const percentage = displayedAllocation.value / prizePool.value;
      // Convert to circle circumference
      return percentage * fullCircle.value;
    });

    async function fetchEstimatedAllocation() {
      isLoading.value = true;
      try {
        const responsePromise = apiService.getCurrentPotentialAllocation();
        const response = await responsePromise;
        const percentageAllocation = parseFloat(response);
        
        if (isNaN(percentageAllocation)) {
          throw new Error("Invalid percentage allocation received");
        }

        const totalPrizePool = parseFloat(prizePool.value);

        if (isNaN(totalPrizePool)) {
          throw new Error("Invalid prize pool value");
        }

        // Calculate the actual allocation
        const actualAllocation = (percentageAllocation / 100) * totalPrizePool;
        estimatedAllocation.value = actualAllocation.toFixed(4);
      } catch (error) {
        toast.error(`Error fetching allocation: ${error.message}`);
        estimatedAllocation.value = 0;
      } finally {
        isLoading.value = false;
      }
    }

    // Watch for changes in the highestScore
    watch(
      score,
      (newScore) => {
        if (newScore !== null && newScore !== undefined) {
          fetchEstimatedAllocation();
        }
      },
      { immediate: true }
    );

    // Watch for changes in the prizePool
    watch(prizePool, (newPrizePool, oldPrizePool) => {
      console.log(`Prize pool changed from ${oldPrizePool} to ${newPrizePool}`);
      if (newPrizePool !== null && newPrizePool !== undefined) {
        fetchEstimatedAllocation();
      }
    });


    return {
      isLoading,
      displayedAllocation,
      gameState,
      score,
      fullCircle,
      calculateProgress,
    };
  },
};
</script>

<style scoped>
.loading-container {
  display: inline-block;
  width: 100%;
  position: relative;
  padding: 5px;
  overflow: hidden;
  border-radius: 5px;
}

.loading-text {
  color: white;
  font-weight: bold;
  z-index: 1;
  position: relative;
}

.loading-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;
  width: 200%;
  height: 100%;
  background: linear-gradient(90deg, transparent, red, transparent);
  animation: knightRiderAnimation 2s infinite linear;
}

@keyframes knightRiderAnimation {
  0% {
    left: -100%;
  }

  50% {
    left: 100%;
  }

  100% {
    left: -100%;
  }
}

.material-icons {
  vertical-align: baseline;
}

.glow-text {
  text-shadow: 0 0 10px rgba(251, 191, 36, 0.7);
}
</style>
