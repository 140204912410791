import { createStore } from "vuex";
import Web3 from "web3";
import apiService from "../src/assets/js/apiService";
import { account } from "@kolirt/vue-web3-auth";
import axios from "axios";
import { useToast } from "vue-toastification";
import { isWithinPastHours } from "./assets/js/timeStampUtility";
import Big from "big.js";
import chainConfig from "./chainConfig.js";
import { pollTransactionReceipt } from "../src/utils/alchemy";

const toast = useToast();
const ENTRY_FEE = "10";
const ENTRY_FEE_WALLET = process.env.VUE_APP_ENTRY_FEE_WALLET;
const currentEnv = process.env.VUE_APP_ENV || "local";
const currentChainConfig = chainConfig[currentEnv];
const alchemyUrl = currentChainConfig.rpcUrls.default.http[0];

// Duration constants with validation
const GAME_DURATION = Number(process.env.VUE_APP_GAME_DURATION);
const CLAIM_DURATION = Number(process.env.VUE_APP_CLAIM_DURATION);
const TRANSITION_DURATION = Number(process.env.VUE_APP_TRANSITION_DURATION);
const FUNDING_DURATION = Number(process.env.VUE_APP_FUNDING_DURATION);

const store = createStore({
  state() {
    return {
      chainConfig: currentChainConfig,
      alchemyUrl: alchemyUrl,
      userBalance: "0",
      hasEntryFee: false,
      isInitialized: false,
      isGameVisible: false,
      gamesLeft: 0,
      highestScore: null,
      leaderboard: [],
      gameToken: null,
      gameState: null,
      prizePool: null,
      claimEndTime: null,
      remainingTime: 0,
      allocation: null,
      web3: null,
      GameContract: null,
      W3MContract: null,
      web3Address: null,
      error: null,
      lastEntryFeeTx: null,
      gameScriptsLoaded: false,
      scoreSubmitted: false,
      roundParticipants: 0,
      currentRoundTopScores: [],
      gameStartTime: null,
      isTransitioningToClaimTime: false,
      countdown: {
        type: null,
        value: 0,
        interval: null,
        displayText: "0:00",
        progressValue: 0,
      },
      currentTime: Math.floor(Date.now() / 1000),
      roundEndTime: 0,
      fundingEndTime: 0,
      countdownInterval: null,
      isTransitioningToFundingState: false,
      fundingProgress: 0,
      isFundingComplete: false,
      fundingCountdown: 60,
      transitionCountdown: 120,
      isPolling: false,
      hasClaimed: false,
      pollInterval: null,
      totalGames: 0,
      lastRoundScore: 0,
      totalGamesForRound: 10,
      flappyPrice: null,
    };
  },
  mutations: {
    SET_GAME_SCRIPTS_LOADED(state, loaded) {
      state.gameScriptsLoaded = loaded;
    },
    SET_SCORE_SUBMITTED(state) {
      state.scoreSubmitted = true;
      
      try {
        // Get existing score submissions or initialize empty array
        let existingSubmissions = [];
        const storedSubmissions = localStorage.getItem("scoreSubmissions");
        if (storedSubmissions) {
          existingSubmissions = JSON.parse(storedSubmissions);
          if (!Array.isArray(existingSubmissions)) {
            existingSubmissions = [];
          }
        }

        // Update or add submission for current wallet
        const walletIndex = existingSubmissions.findIndex(submission => 
          submission.address.toLowerCase() === account.address.toLowerCase()
        );

        const newSubmission = {
          hasSubmitted: true,
          timestamp: Date.now(),
          address: account.address
        };

        if (walletIndex >= 0) {
          existingSubmissions[walletIndex] = newSubmission;
        } else {
          existingSubmissions.push(newSubmission);
        }

        localStorage.setItem("scoreSubmissions", JSON.stringify(existingSubmissions));
      } catch (error) {
        console.error('Error updating score submissions:', error);
      }
    },
    SET_LAST_ENTRY_FEE_TX(state, tx) {
      state.lastEntryFeeTx = tx;
    },
    SET_USER_BALANCE(state, balance) {
      state.userBalance = balance;
    },
    SET_ENTRY_FEE_STATUS(state, status) {
      state.hasEntryFee = status;
    },
    SET_INITIALIZED(state, initialized) {
      state.isInitialized = initialized;
    },
    SET_GAME_VISIBILITY(state, visibility) {
      state.isGameVisible = visibility;
    },
    UPDATE_GAMES_LEFT(state, data) {
      state.gamesLeft = data;
    },
    UPDATE_HIGHEST_SCORE(state, score) {
      state.highestScore = score;
    },
    UPDATE_LEADERBOARD(state, leaderboard) {
      state.leaderboard = leaderboard;
    },
    UPDATE_PRIZE_POOL(state, prizePool) {
      state.prizePool = prizePool;
    },
    SET_GAME_TOKEN(state, token) {
      state.gameToken = token;
      localStorage.setItem("gameToken", token);
    },
    SET_WEB3(state, web3Instance) {
      state.web3 = web3Instance;
    },
    SET_GAME_CONTRACT(state, contractInstance) {
      state.GameContract = contractInstance;
    },
    SET_W3M_CONTRACT(state, contractInstance) {
      state.W3MContract = contractInstance;
    },
    SET_GAME_STATE(state, newState) {
      state.gameState = newState;
    },
    SET_CLAIM_END_TIME(state, time) {
      state.claimEndTime = time;
    },
    SET_ALLOCATION(state, allocation) {
      state.allocation = allocation;
    },
    SET_REMAINING_TIME(state, time) {
      state.remainingTime = time;
    },
    SET_WEB3_ADDRESS(state, address) {
      state.web3Address = address;
    },
    SET_ERROR(state, error) {
      state.error = error;
    },
    CLEAR_USER_DATA(state) {
      state.userBalance = "0";
      state.isInitialized = false;
      state.isGameVisible = false;
      state.gamesLeft = null;
      state.highestScore = null;
      state.leaderboard = [];
      state.gameToken = null;
      state.gameState = null;
      state.prizePool = null;
      state.claimEndTime = null;
      state.remainingTime = null;
      state.allocation = null;
      state.web3 = null;
      state.GameContract = null;
      state.W3MContract = null;
      state.web3Address = null;
      state.lastEntryFeeTx = null;
    },
    SET_ROUND_PARTICIPANTS(state, count) {
      state.roundParticipants = count;
    },
    SET_CURRENT_ROUND_TOP_SCORES(state, scores) {
      state.currentRoundTopScores = scores;
    },
    SET_GAME_START_TIME(state, time) {
      state.gameStartTime = time;
    },
    SET_IS_TRANSITIONING_TO_CLAIM_TIME(state, value) {
      state.isTransitioningToClaimTime = value;
      localStorage.setItem(
        "transitionState",
        JSON.stringify({
          type: "claim",
          isTransitioning: value,
          timestamp: Date.now(),
        })
      );
    },
    SET_COUNTDOWN(state, { type, value, displayText, progressValue }) {
      const getProgressValue = (value, type) => {
        const circumference = 2 * Math.PI * 35;
        switch (type) {
          case "claim":
          case "claimPeriod":
            return (value / CLAIM_DURATION) * circumference;
          case "game":
            return (value / GAME_DURATION) * circumference;
          case "transition":
          case "toClaimTransition":
          case "toFundingTransition":
            return (value / TRANSITION_DURATION) * circumference;
          case "funding":
            return (value / FUNDING_DURATION) * circumference;
          default:
            return 0;
        }
      };

      const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = Math.floor(seconds % 60);
        return `${minutes}:${remainingSeconds.toString().padStart(2, "0")}`;
      };

      const newCountdown = {
        type,
        value,
        interval: state.countdown?.interval,
        displayText: displayText || formatTime(value),
        progressValue: progressValue || getProgressValue(value, type),
      };

      state.countdown = newCountdown;
    },
    SET_COUNTDOWN_INTERVAL(state, interval) {
      if (state.countdown.interval) {
        clearInterval(state.countdown.interval);
      }
      state.countdown.interval = interval;
    },
    CLEAR_COUNTDOWN(state) {
      if (state.countdown.interval) {
        clearInterval(state.countdown.interval);
      }
      state.countdown = {
        type: null,
        value: 0,
        interval: null,
        displayText: "0:00",
        progressValue: 0,
      };
    },
    UPDATE_CURRENT_TIME(state) {
      state.currentTime = Math.floor(Date.now() / 1000);
    },
    SET_ROUND_END_TIME(state, time) {
      state.roundEndTime = time;
    },
    SET_FUNDING_END_TIME(state, time) {
      state.fundingEndTime = time;
    },
    UPDATE_REMAINING_TIME(state, time) {
      state.remainingTime = time;
    },
    SET_IS_TRANSITIONING_TO_FUNDING_STATE(state, value) {
      state.isTransitioningToFundingState = value;
    },
    CLEAR_COUNTDOWN_INTERVAL(state) {
      if (state.countdownInterval) {
        clearInterval(state.countdownInterval);
        state.countdownInterval = null;
      }
    },
    SET_TRANSITIONING_TO_FUNDING_STATE(state, value) {
      state.isTransitioningToFundingState = value;
    },
    SET_PRIZE_POOL(state, amount) {
      state.prizePool = amount;
    },
    SET_FUNDING_PROGRESS(state, progress) {
      state.fundingProgress = progress;
    },
    SET_IS_FUNDING_COMPLETE(state, value) {
      state.isFundingComplete = value;
    },
    SET_FUNDING_COUNTDOWN(state, value) {
      state.fundingCountdown = value;
    },
    SET_GAMES_LEFT(state, value) {
      state.gamesLeft = Number(value);
    },
    SET_HIGH_SCORE(state, value) {
      state.highScore = value;
    },
    SET_TRANSITION_COUNTDOWN(state, value) {
      state.transitionCountdown = value;
    },
    SET_IS_POLLING(state, value) {
      state.isPolling = value;
    },
    SET_HAS_CLAIMED(state, value) {
      state.hasClaimed = value;
      
      try {
        const existingClaims = JSON.parse(localStorage.getItem("claimStates") || "[]");
        
        // Find and update the current wallet's claim state
        const updatedClaims = existingClaims.map(claim => {
          if (claim.address.toLowerCase() === account.address.toLowerCase()) {
            return {
              ...claim,
              hasClaimed: value,
              timestamp: Date.now()
            };
          }
          return claim;
        });

        localStorage.setItem("claimStates", JSON.stringify(updatedClaims));
      } catch (error) {
        console.error('Error updating claim states:', error);
      }
    },
    SET_POLL_INTERVAL(state, interval) {
      state.pollInterval = interval;
    },
    SET_TOTAL_GAMES(state, total) {
      state.totalGames = total;
    },
    SET_LAST_ROUND_SCORE(state, score) {
      state.lastRoundScore = Number(score);
    },
    SET_TOTAL_GAMES_FOR_ROUND(state, total) {
      state.totalGamesForRound = total;
    },
    SET_FLAPPY_PRICE(state, price) {
      state.flappyPrice = price;
    },
  },
  getters: {
    isWalletConnected: () => account.connected,
    walletAddress: () => account.address,
    canPlayGame: (state) => {
      return parseFloat(state.userBalance) >= 500 && state.hasEntryFee;
    },
  },
  actions: {
    async authenticateUser() {
      try {
        if (!account.connected) {
          throw new Error("Web3 address not set");
        }
        let web3Address = account.address;
        const response = await axios.post(
          process.env.VUE_APP_BACKEND_URL + "/api/authenticate",
          { web3Address }
        );
        const { token } = response.data;
        localStorage.setItem("userToken", token);
        //toast.success("Authentication successful!");
        return token; // Return the token
      } catch (error) {
        console.error("Authentication failed:", error);
        toast.error("Authentication failed. Please try again.");
        throw error;
      }
    },
    async checkTokenValidity() {
      try {
        // Make a lightweight API call to check token validity
        await apiService.getLeftGames();
        return true;
      } catch (error) {
        if (error.response && error.response.status === 401) {
          toast.error("Invalid authentication.");
          return false;
        }
        toast.error("An error occurred while checking authentication.");
        throw error;
      }
    },
    async reauthenticate({ dispatch }) {
      try {
        localStorage.removeItem("userToken");
        await dispatch("authenticateUser");
        await dispatch("fetchInitialData");
        toast.success("Re-authenticated successfully!");
      } catch (error) {
        toast.error("Failed to re-authenticate. Please reconnect.");
        // You might want to redirect to a login page here
      }
    },
    async initializeContracts({ state, commit }) {
      if (!account.connected) return;
      const { gameContractAddress, tokenContractAddress, gameAbi, tokenAbi } =
        state.chainConfig.contracts;
      const web3 = new Web3(window.ethereum);

      const GameContract = new web3.eth.Contract(gameAbi, gameContractAddress);
      const W3MContract = new web3.eth.Contract(tokenAbi, tokenContractAddress);
      commit("SET_WEB3_ADDRESS", account.address);
      commit("SET_WEB3", web3);
      commit("SET_GAME_CONTRACT", GameContract);
      commit("SET_W3M_CONTRACT", W3MContract);
      //commit("SET_INITIALIZED", true);
    },
    async fetchInitialData({ state, dispatch, commit }) {
      try {
        await dispatch("authenticateUser");
        await dispatch("fetchGameState");

        // Only handle claim states if we're in claim period (state 2)
        if (state.gameState === 2) {
          // Get existing claims array or initialize empty array
          let existingClaims = [];
          const storedClaims = localStorage.getItem("claimStates");
          if (storedClaims) {
            existingClaims = JSON.parse(storedClaims);
            if (!Array.isArray(existingClaims)) {
              existingClaims = [];
            }
          }

          // Check if current wallet exists in claims
          const walletExists = existingClaims.some(claim => 
            claim.address.toLowerCase() === account.address.toLowerCase()
          );

          // If wallet doesn't exist, add it with default false claim state
          if (!walletExists) {
            existingClaims.push({
              hasClaimed: false,
              timestamp: Date.now(),
              address: account.address
            });
            localStorage.setItem("claimStates", JSON.stringify(existingClaims));
          }

          // Set the current wallet's claim state
          const currentWalletClaim = existingClaims.find(claim => 
            claim.address.toLowerCase() === account.address.toLowerCase()
          );
          commit("SET_HAS_CLAIMED", currentWalletClaim.hasClaimed);
        } else {
          // Clear stored claim states if we're not in claim period
          localStorage.removeItem("claimStates");
          commit("SET_HAS_CLAIMED", false);
        }

        // Check stored score submissions for current wallet
        const storedSubmissions = localStorage.getItem("scoreSubmissions");
        if (storedSubmissions) {
          const submissions = JSON.parse(storedSubmissions);
          const currentWalletSubmission = submissions.find(submission => 
            submission.address.toLowerCase() === account.address.toLowerCase()
          );
          
          if (currentWalletSubmission && currentWalletSubmission.hasSubmitted) {
            commit("SET_SCORE_SUBMITTED");
          }
        }

        const fetchPromises = [
          dispatch("fetchUserBalance"),
          dispatch("fetchAllocation"),
          dispatch("fetchGameState"),
          dispatch("fetchPrizePool"),
          dispatch("fetchInitialHighScore"),
          dispatch("fetchInitialGamesLeft"),
          dispatch("fetchInitialLeaderboard"),
          dispatch("fetchClaimEndTime"),
          dispatch("fetchUser"),
          dispatch("fetchFlappyPrice"),
        ];

        // Use Promise.allSettled to handle partial failures
        const results = await Promise.allSettled(fetchPromises);

        // Check for any failures
        const failures = results.filter((r) => r.status === "rejected");
        if (failures.length > 0) {
          console.error("Some initial data fetches failed:", failures);
          toast.warning(
            "Some game data could not be loaded. You may need to refresh."
          );
        }

        commit("SET_INITIALIZED", true);
        await dispatch("checkEntryFeeStatus");
        await dispatch("initializeTimers");
      } catch (error) {
        console.error("Error during initial data fetch:", error);
        toast.error("Failed to initialize game. Please refresh the page.");
        commit("SET_ERROR", "Failed to initialize game");
      }
    },
    async fetchGameState({ state, commit }) {
      if (!state.GameContract) return null;
      try {
        const gameState = await state.GameContract.methods
          .getCurrentState()
          .call();
        commit("SET_GAME_STATE", Number(gameState));
        return Number(gameState);
      } catch (error) {
        console.error("Failed to fetch game state:", error);
        toast.error("Failed to fetch game state.");
        return null;
      }
    },
    async fetchPrizePool({ state, commit }) {
      if (!state.W3MContract || !state.GameContract) return;
      try {
        const balance = await state.W3MContract.methods
          .balanceOf(state.GameContract.options.address)
          .call();
        const prizePool = state.web3.utils.fromWei(balance, "ether");
        commit("UPDATE_PRIZE_POOL", prizePool);
      } catch (error) {
        console.error("Failed to fetch prize pool:", error);
        toast.error("Failed to fetch prize pool.");
      }
    },
    async fetchClaimEndTime({ state, commit }) {
      if (!state.GameContract) return;
      try {
        const claimEndTime = await state.GameContract.methods
          .claimEndTime()
          .call();
        const currentTime = Math.floor(Date.now() / 1000);
        const remainingTime = Math.max(0, Number(claimEndTime) - currentTime);

        commit("SET_CLAIM_END_TIME", Number(claimEndTime));
        commit("SET_REMAINING_TIME", remainingTime);

        return remainingTime;
      } catch (error) {
        console.error("Failed to fetch claim end time:", error);
        return null;
      }
    },
    async fetchAllocation({ state, commit }) {
      if (!state.GameContract || !account.address) {
        console.log("Missing requirements for fetchAllocation:", {
          hasContract: !!state.GameContract,
          hasAddress: !!account.address,
        });
        return;
      }

      try {
        const allocation = await state.GameContract.methods
          .allocations(account.address)
          .call();

        // Convert from wei to ether before committing
        const allocationInEther = state.web3.utils.fromWei(allocation, "ether");
        commit("SET_ALLOCATION", allocationInEther);
      } catch (error) {
        console.error("Failed to fetch allocation:", error);
        toast.error("Failed to fetch allocation.");
      }
    },
    async fetchInitialGamesLeft({ commit }) {
      try {
        const response = await apiService.getLeftGames();
        commit("UPDATE_GAMES_LEFT", response.data.leftGames);
      } catch (error) {
        console.error("Error fetching initial games left:", error);
      }
    },
    async fetchInitialHighScore({ commit }) {
      try {
        const response = await apiService.getHighestScore();
        commit("UPDATE_HIGHEST_SCORE", response.data.currentRoundScore);
      } catch (error) {
        console.error("Error fetching initial highest score:", error);
      }
    },
    async fetchInitialLeaderboard({ commit }) {
      try {
        const response = await apiService.getLeaderboard();
        commit("UPDATE_LEADERBOARD", response.data.allTimeLeaderboard);
      } catch (error) {
        console.error("Error fetching initial leaderboard:", error);
        toast.error("Error fetching initial leaderboard.");
      }
    },
    async fetchUser({ commit }) {
      try {
        const response = await apiService.getUser();
        let userData = response.data.user;

        // Get existing score submissions
        let existingSubmissions = [];
        const storedSubmissions = localStorage.getItem("scoreSubmissions");
        if (storedSubmissions) {
          existingSubmissions = JSON.parse(storedSubmissions);
          if (!Array.isArray(existingSubmissions)) {
            existingSubmissions = [];
          }
        }

        // Check if current wallet exists in submissions
        const walletExists = existingSubmissions.some(submission => 
          submission.address.toLowerCase() === account.address.toLowerCase()
        );

        // If wallet doesn't exist, add it with default state
        if (!walletExists) {
          existingSubmissions.push({
            hasSubmitted: !!userData.scoreSignature, // Set based on API response
            timestamp: Date.now(),
            address: account.address
          });
          localStorage.setItem("scoreSubmissions", JSON.stringify(existingSubmissions));
        }

        // Set the current wallet's submission state
        const currentWalletSubmission = existingSubmissions.find(submission => 
          submission.address.toLowerCase() === account.address.toLowerCase()
        );
        
        if (currentWalletSubmission && currentWalletSubmission.hasSubmitted) {
          commit("SET_SCORE_SUBMITTED");
        }

        if (userData.lastRoundScore) {
          commit("SET_LAST_ROUND_SCORE", userData.lastRoundScore);
        }
      } catch (error) {
        console.error("Error fetching user:", error);
        toast.error("Error fetching user.");
      }
    },
    async fetchUserBalance({ state, commit }) {
      if (!state.W3MContract) return;
      try {
        const balance = await state.W3MContract.methods
          .balanceOf(account.address)
          .call();
        const balanceInEther = state.web3.utils.fromWei(balance, "ether");
        commit("SET_USER_BALANCE", balanceInEther);
      } catch (error) {
        console.error("Failed to fetch user balance:", error);
        toast.error("Failed to fetch user balance.");
        commit("SET_USER_BALANCE", "0");
      }
    },
    async fetchRemainingTime({ state, commit }) {
      try {
        if (!state.GameContract) {
          throw new Error("GameContract is not initialized");
        }
        const gameStartTime = await state.GameContract.methods
          .gameStartTime()
          .call();
        const gameDuration = await state.GameContract.methods
          .GAME_DURATION()
          .call();
        const endTime = Number(gameStartTime) + Number(gameDuration);

        const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds
        const remainingTime = endTime - currentTime;
        //console.log("Remaining time (seconds):", remainingTime);

        commit("SET_REMAINING_TIME", remainingTime);
      } catch (error) {
        console.error("Failed to fetch remaining time:", error);
        toast.error("Failed to fetch remaining time.");
      }
    },
    async clearUserData({ state, commit }) {
      commit("CLEAR_USER_DATA", state);
      localStorage.removeItem("lastEntryFeeTimestamp");
    },
    async payEntryFee({ state, commit, dispatch }) {
      try {
        // 1. Validate wallet connection
        if (!account.connected || !account.address) {
          console.error("❌ Wallet not connected:", {
            isConnected: account.connected,
            address: account.address,
          });
          toast.error("Please connect your wallet first");
          return false;
        }

        // Check balance
        const balance = await state.W3MContract.methods
          .balanceOf(account.address)
          .call();
        const balanceInEther = state.web3.utils.fromWei(balance, "ether");

        if (parseFloat(balanceInEther) < parseFloat(ENTRY_FEE)) {
          toast.error(`Insufficient balance. You need ${ENTRY_FEE} $FLAPPY`);
          return false;
        }

        // Prepare transaction parameters
        const entryFeeWei = state.web3.utils.toWei(ENTRY_FEE, "ether");
        const txParams = {
          from: account.address,
          to: state.W3MContract.options.address,
          data: state.W3MContract.methods
            .transfer(ENTRY_FEE_WALLET, entryFeeWei)
            .encodeABI(),
        };

        console.log("🔧 Transaction parameters prepared:", txParams);

        // Send transaction
        const txHash = await window.ethereum.request({
          method: "eth_sendTransaction",
          params: [txParams],
        });

        console.log("📨 Transaction sent, waiting for confirmation:", {
          txHash,
        });

        // Poll for receipt using Alchemy
        const receipt = await pollTransactionReceipt(txHash, alchemyUrl);

        if (!receipt || !receipt.status) {
          console.error("❌ Transaction failed or receipt invalid:", {
            receipt,
          });
          toast.error("Transaction failed. Please try again.");
          return false;
        }

        console.log("🎉 Transaction confirmed:", { receipt });

        // Post-receipt processing
        const gameStartTime = await state.GameContract.methods
          .gameStartTime()
          .call();

        // Get existing entry fee data array
        let existingEntryFees = [];
        const storedEntryFees = localStorage.getItem("lastEntryFeeData");
        if (storedEntryFees) {
          existingEntryFees = JSON.parse(storedEntryFees);
          if (!Array.isArray(existingEntryFees)) {
            existingEntryFees = [];
          }
        }

        // Update or add entry fee data for current wallet
        const walletIndex = existingEntryFees.findIndex(entry => 
          entry.address.toLowerCase() === account.address.toLowerCase()
        );

        const newEntryFeeData = {
          timestamp: Math.floor(Date.now() / 1000),
          address: account.address,
          gameStartTime: gameStartTime.toString()
        };

        if (walletIndex >= 0) {
          existingEntryFees[walletIndex] = newEntryFeeData;
        } else {
          existingEntryFees.push(newEntryFeeData);
        }

        localStorage.setItem("lastEntryFeeData", JSON.stringify(existingEntryFees));

        // Update state
        commit("SET_LAST_ENTRY_FEE_TX", receipt.transactionHash);
        commit("SET_ENTRY_FEE_STATUS", true);
        commit("SET_GAME_VISIBILITY", true);
        await dispatch("fetchUserBalance");

        toast.success("Entry fee paid successfully!");
        return true;
      } catch (error) {
        console.error("❌ payEntryFee process failed:", error);
        toast.error("An error occurred while processing your transaction.");
        return false;
      }
    },
    async checkEntryFeeStatus({ state, commit, dispatch }) {
      // Check if we have all required contracts
      if (!state.isInitialized || !state.web3 || !state.GameContract || !state.W3MContract) {
        console.log("❌ Missing required contracts");
        commit("SET_ENTRY_FEE_STATUS", false);
        return;
      }

      const storedEntryFees = localStorage.getItem("lastEntryFeeData");
      
      if (!storedEntryFees) {
        return await checkBlockchainEntryFee({ state, commit, dispatch });
      }

      try {
        const entryFees = JSON.parse(storedEntryFees);
        const currentWalletEntry = entryFees.find(entry => 
          entry.address.toLowerCase() === account.address.toLowerCase()
        );

        if (!currentWalletEntry) {
          return await checkBlockchainEntryFee({ state, commit, dispatch });
        }

        const currentGameStartTime = await state.GameContract.methods
          .gameStartTime()
          .call();

        const isTimeValid = isWithinPastHours(parseInt(currentWalletEntry.timestamp), 1);
        const isSameRound = currentWalletEntry.gameStartTime === currentGameStartTime.toString();

        if (isTimeValid && isSameRound) {
          console.log("✅ Entry fee valid");
          commit("SET_ENTRY_FEE_STATUS", true);
          commit("SET_GAME_VISIBILITY", true);
          return;
        }

        console.log("❌ Entry fee invalid");
        commit("SET_ENTRY_FEE_STATUS", false);
      } catch (error) {
        console.error("Error processing stored entry fee data:", error);
        commit("SET_ENTRY_FEE_STATUS", false);
      }
    },

    async initializeCountdown({ state }) {
      console.log("Initializing countdown for gameState:", state.gameState);
      
/*       if (state.gameState === 0 && Number(state.prizePool) >= 100) {
        dispatch('startCountdown', {
          type: 'funding',
          duration: process.env.VUE_APP_FUNDING_DURATION
        });
      } */
    },
    async fetchRecentTransactions({ state, commit }) {
      if (!state.isInitialized || !state.W3MContract || !state.web3) {
        console.error("Web3 or contracts not initialized");
        return null;
      }

      try {
        const latestBlock = await state.web3.eth.getBlockNumber();
        const fromBlock = Big(latestBlock).minus(5760).toString(); // Approximately 24 hours worth of blocks

        const events = await state.W3MContract.getPastEvents("Transfer", {
          filter: {
            from: state.web3Address,
            to: ENTRY_FEE_WALLET,
          },
          fromBlock: fromBlock,
          toBlock: "latest",
        });

        const entryFeeWei = state.web3.utils.toWei(ENTRY_FEE, "ether");
        const validTransactions = events.filter((event) => {
          return Big(event.returnValues.value).eq(Big(entryFeeWei));
        });

        if (validTransactions.length > 0) {
          const latestValidTx = validTransactions[validTransactions.length - 1];
          commit("SET_LAST_ENTRY_FEE_TX", latestValidTx.transactionHash);
          return latestValidTx.transactionHash;
        }

        return null;
      } catch (error) {
        console.error("Failed to fetch recent transactions:", error);
        toast.error("Failed to check recent entry fee payments.");
        return null;
      }
    },
    async setScoreSubmitted({ commit }) {
      commit("SET_SCORE_SUBMITTED");
    },
    async fetchRoundParticipants({ commit }) {
      try {
        const participants = await apiService.getRoundParticipants();
        commit("SET_ROUND_PARTICIPANTS", Number(participants));
      } catch (error) {
        console.error("Failed to fetch round participants:", error);
        toast.error("Failed to fetch round participants.");
      }
    },
    async fetchCurrentRoundTopScores({ commit }) {
      try {
        const scores = await apiService.getCurrentRoundTopScores();
        commit("SET_CURRENT_ROUND_TOP_SCORES", scores);
      } catch (error) {
        console.error("Failed to fetch current round top scores:", error);
        toast.error("Failed to fetch current round top scores.");
      }
    },
    startCountdown({ commit, state, dispatch }, { type, duration }) {
      console.log(`🕒 Starting countdown - Type: ${type}, Duration: ${duration}`);
      
      if (state.countdown.interval) {
        console.log(`⚠️ Clearing existing countdown of type: ${state.countdown.type}`);
        clearInterval(state.countdown.interval);
      }

      commit("SET_COUNTDOWN", { type, value: duration });

      const interval = setInterval(() => {
        const newValue = state.countdown.value - 1;

        if (newValue <= 0) {
          console.log(`⏰ Countdown finished - Type: ${type}`);
          clearInterval(interval);
          commit("CLEAR_COUNTDOWN");

          switch (type) {
            case "funding":
              dispatch("pollForStateChange", {
                targetState: 1,
                onSuccess: () => window.location.reload()
              });
              break;
            case "claim":
            case "claimPeriod":
              dispatch("transitionToFundingState");
              break;
            case "toClaimTransition":
              dispatch("pollForStateChange", {
                targetState: 2,
                onSuccess: () => {
                  commit("SET_LAST_ROUND_SCORE", state.highestScore);
                  commit("SET_IS_TRANSITIONING_TO_CLAIM_TIME", false);
                  dispatch("startCountdown", {
                    type: "claim",
                    duration: CLAIM_DURATION,
                  });
                },
              });
              break;
            case "toFundingTransition":
              dispatch("pollForStateChange", {
                targetState: 0,
                onSuccess: () => {
                  commit("SET_IS_TRANSITIONING_TO_FUNDING_STATE", false);
                  if (Number(state.prizePool) >= 100) {
                    dispatch("startCountdown", {
                      type: "funding",
                      duration: FUNDING_DURATION,
                    });
                  }
                },
              });
              break;
            case "game":
              dispatch("transitionToClaimState");
              break;
            case "fundingPeriod":
              dispatch("transitionToGameState");
              break;
            default:
              break;
          }
        } else {
          commit("SET_COUNTDOWN", { type, value: newValue });
        }
      }, 1000);

      commit("SET_COUNTDOWN_INTERVAL", interval);
    },

    stopCountdown({ commit }) {
      commit("CLEAR_COUNTDOWN_INTERVAL");
    },
    transitionToClaimState({ commit, dispatch }) {
      commit("SET_IS_TRANSITIONING_TO_CLAIM_TIME", true);

      dispatch("startCountdown", {
        type: "transition",
        duration: TRANSITION_DURATION,
      });

      // Start polling with an interval
      const pollInterval = setInterval(async () => {
        try {
          const newGameState = await dispatch("fetchGameState");

          if (newGameState === 2) {
            console.log("Successfully transitioned to claim state");
            clearInterval(pollInterval);
            commit("SET_IS_TRANSITIONING_TO_CLAIM_TIME", false);

            await Promise.all([
              dispatch("fetchAllocation"),
              dispatch("fetchPrizePool"),
              dispatch("fetchGamesLeft"),
            ]);

            dispatch("startCountdown", {
              type: "claim",
              duration: CLAIM_DURATION,
            });
          }
        } catch (error) {
          console.error("Error during claim state polling:", error);
        }
      }, 5000);

      // Safety timeout
      setTimeout(() => {
        clearInterval(pollInterval);
        dispatch("fetchGameState").then((newGameState) => {
          if (newGameState === 2) {
            commit("SET_IS_TRANSITIONING_TO_CLAIM_TIME", false);
            dispatch("startCountdown", {
              type: "claim",
              duration: CLAIM_DURATION,
            });
          } else {
            // If we still haven't transitioned after timeout, force reload
            window.location.reload();
          }
        });
      }, TRANSITION_DURATION * 1000);
    },

    transitionToFundingState({ state, commit, dispatch }) {
      commit("CLEAR_COUNTDOWN");
      commit("SET_IS_TRANSITIONING_TO_FUNDING_STATE", true);

      dispatch("startCountdown", {
        type: "transition",
        duration: TRANSITION_DURATION,
      });

      // Start polling with an interval
      const pollInterval = setInterval(async () => {
        try {
          const newGameState = await dispatch("fetchGameState");

          if (newGameState === 0) {
            console.log("Successfully transitioned to funding state");
            clearInterval(pollInterval);
            commit("SET_IS_TRANSITIONING_TO_FUNDING_STATE", false);
            commit("SET_HAS_CLAIMED", false); // Reset claimed state

            await Promise.all([
              dispatch("fetchPrizePool"),
              dispatch("fetchGamesLeft"),
              dispatch("fetchFlappyPrice"),
            ]);

            if (Number(state.prizePool) >= 100) {
              dispatch("startCountdown", {
                type: "funding",
                duration: FUNDING_DURATION,
              });
            }
          }
        } catch (error) {
          console.error("Error during funding state polling:", error);
        }
      }, 5000); // Poll every 5 seconds

      // Safety timeout
      setTimeout(() => {
        clearInterval(pollInterval);
        dispatch("fetchGameState").then((newGameState) => {
          if (newGameState === 0) {
            commit("SET_IS_TRANSITIONING_TO_FUNDING_STATE", false);
            commit("SET_HAS_CLAIMED", false); // Reset claimed state
            if (Number(state.prizePool) >= 100) {
              dispatch("startCountdown", {
                type: "funding",
                duration: FUNDING_DURATION,
              });
            }
          } else {
            // If we still haven't transitioned after timeout, force reload
            window.location.reload();
          }
        });
      }, TRANSITION_DURATION * 1000);
    },
    async fetchGameEndTime({ state, commit }) {
      if (!state.GameContract) {
        console.log("No GameContract available");
        return null;
      }
      try {
        const gameStartTime = await state.GameContract.methods
          .gameStartTime()
          .call();
        const currentTime = Math.floor(Date.now() / 1000);
        const remainingTime = Math.max(
          0,
          Number(gameStartTime) + GAME_DURATION - currentTime
        );

/*         console.log("Game time calculation:", {
          gameStartTime: Number(gameStartTime),
          currentTime,
          gameDuration: GAME_DURATION,
          remainingTime,
          readableStartTime: new Date(
            Number(gameStartTime) * 1000
          ).toLocaleString(),
          readableCurrentTime: new Date(currentTime * 1000).toLocaleString(),
        }); */

        commit("SET_GAME_START_TIME", Number(gameStartTime));
        commit("SET_REMAINING_TIME", remainingTime);

        return remainingTime;
      } catch (error) {
        console.error("Failed to fetch game end time:", error);
        return null;
      }
    },
    async initializeTimers({ state, dispatch, commit }) {
      //console.log("⏰ Initializing timers with state:", {
      //  gameState: state.gameState,
      //  isPolling: state.isPolling,
      //  prizePool: state.prizePool,
      //  isTransitioningToClaimTime: state.isTransitioningToClaimTime,
      //  isTransitioningToFundingState: state.isTransitioningToFundingState
      //});

      if (!state.GameContract) {
        console.error("GameContract not initialized!");
        return;
      }

      try {
        // First check transition states
        if (
          state.isTransitioningToClaimTime ||
          state.isTransitioningToFundingState
        ) {
          dispatch("startPolling"); // Always poll during transitions
          return;
        }

        let gameRemainingTime;
        let claimRemainingTime;

        // Then handle main game states
        switch (state.gameState) {
          case 0: // Funding
            if (Number(state.prizePool) >= 100) {
              dispatch("startCountdown", {
                type: "funding",
                duration: FUNDING_DURATION,
                onComplete: () => {
                  dispatch("transitionToGameState");
                  dispatch("startPolling");
                },
              });
            }
            //dispatch("startPolling"); // Always poll during funding
            break;

          case 1: // Active Game
            gameRemainingTime = await dispatch("fetchGameEndTime");
            if (gameRemainingTime > 0) {
              dispatch("startCountdown", {
                type: "game",
                duration: gameRemainingTime,
              });

              // Special polling rules for game state
              const FIVE_MINUTES = 5 * 60; // 300 seconds
              if (gameRemainingTime <= FIVE_MINUTES) {
                dispatch("startPolling");
              } else {
                // Set up a timeout to start polling when 5 minutes remain
                const timeUntilPolling = gameRemainingTime - FIVE_MINUTES;
                setTimeout(() => {
                  dispatch("startPolling");
                }, timeUntilPolling * 1000);
              }
            } else {
              commit("SET_IS_TRANSITIONING_TO_CLAIM_TIME", true);
              dispatch("startCountdown", {
                type: "toClaimTransition",
                duration: TRANSITION_DURATION,
              });
              dispatch("startPolling"); // Poll during transition
            }
            break;

          case 2: // Claim
            claimRemainingTime = await dispatch("fetchClaimEndTime");
            if (claimRemainingTime > 0) {
              dispatch("startCountdown", {
                type: "claimPeriod",
                duration: claimRemainingTime,
              });
            } else {
              commit("SET_IS_TRANSITIONING_TO_FUNDING_STATE", true);
              dispatch("startCountdown", {
                type: "toFundingTransition",
                duration: TRANSITION_DURATION,
              });
            }
            dispatch("startPolling"); // Always poll during claim
            break;

          default:
            console.error("Unknown game state:", state.gameState);
            break;
        }
      } catch (error) {
        console.error("Error in initializeTimers:", error);
        toast.error(
          "Failed to initialize game timers. Please refresh the page."
        );
      }
    },
    pollForStateChange: async ({ dispatch, commit }, { targetState, onSuccess }) => {
      try {
        const newGameState = await dispatch("fetchGameState");

        if (newGameState === targetState) {
          // If transitioning to claim state (2), fetch allocation first
          if (targetState === 2) {
            await dispatch("fetchAllocation");
          }

          // If transitioning to running state (1), reset everything for new game round
          if (targetState === 1) {
            // Clear all round-specific data
            dispatch("clearScoreSubmissions");
            commit("CLEAR_COUNTDOWN");
            
            // Start new game countdown
            dispatch("startCountdown", {
              type: "game",
              duration: GAME_DURATION
            });

            // Fetch fresh game data
            await Promise.all([
              dispatch("fetchPrizePool"),
              dispatch("fetchGamesLeft"),
              dispatch("fetchGameEndTime")
            ]);
          }

          // Then fetch other common data
          await Promise.all([
            dispatch("fetchPrizePool"),
            // Only fetch games left if we're transitioning to game state
            ...(targetState === 1 ? [dispatch("fetchGamesLeft")] : []),
          ]);

          if (typeof onSuccess === "function") {
            onSuccess();
          }
          return true;
        }
        return false;
      } catch (error) {
        console.error("Error polling for state change:", error);
        return false;
      }
    },
    transitionToGameState({ state, commit, dispatch }) {
      // Clear any existing polling
      if (state.pollInterval) {
        clearInterval(state.pollInterval);
        commit("SET_IS_POLLING", false);
      }

      dispatch("startCountdown", {
        type: "transition",
        duration: TRANSITION_DURATION,
      });

      const pollInterval = setInterval(async () => {
        await dispatch("pollForStateChange", {
          targetState: 1,
          onSuccess: () => {
            clearInterval(pollInterval);
            window.location.reload();
          }
        });
      }, 5000);
    },
    async fetchGamesLeft({ commit }) {
      try {
        const response = await apiService.getLeftGames();
        if (response && response.data) {
          commit("UPDATE_GAMES_LEFT", response.data.leftGames);
          // Calculate total games (base games + bought games)
          const totalGames = Math.max(10, response.data.leftGames);
          commit("SET_TOTAL_GAMES_FOR_ROUND", totalGames);
        }
      } catch (error) {
        console.error("Failed to fetch games left:", error);
        toast.error("Failed to fetch remaining games");
        throw error;
      }
    },
    async buyMoreGames({ state, dispatch }, amount) {
      try {
        // 1. Validate wallet connection
        if (!account.connected || !account.address) {
          console.error("❌ Wallet not connected:", {
            isConnected: account.connected,
            address: account.address,
          });
          toast.error("Please connect your wallet first");
          return false;
        }

        // 2. Validate game state
        if (state.gameState !== 1) {
          toast.error(
            "Games can only be purchased during the active game period"
          );
          return false;
        }

        // 3. Calculate price based on amount
        const price = amount === 5 ? "5" : amount === 10 ? "8" : "10";
        const priceInWei = state.web3.utils.toWei(price, "ether");

        // 4. Check balance
        const userBalance = Number(state.userBalance);
        if (userBalance < Number(price)) {
          toast.error(`Insufficient balance. You need ${price} $FLAPPY`);
          return false;
        }

        // 5. Prepare transaction parameters
        const txParams = {
          from: account.address,
          to: state.W3MContract.options.address,
          data: state.W3MContract.methods
            .transfer(ENTRY_FEE_WALLET, priceInWei)
            .encodeABI(),
        };

        console.log("🔧 Transaction parameters prepared:", txParams);

        // 6. Send transaction
        const txHash = await window.ethereum.request({
          method: "eth_sendTransaction",
          params: [txParams],
        });

        console.log("📨 Transaction sent, waiting for confirmation:", {
          txHash,
        });

        // 7. Poll for receipt using Alchemy
        const receipt = await pollTransactionReceipt(txHash, alchemyUrl);

        if (!receipt || !receipt.status) {
          console.error("❌ Transaction failed or receipt invalid:", {
            receipt,
          });
          toast.error("Transaction failed. Please try again.");
          return false;
        }

        console.log("🎉 Transaction confirmed:", { receipt });

        // 8. Backend verification with retries
        let retryCount = 0;
        const maxRetries = 3;

        while (retryCount < maxRetries) {
          try {
            await apiService.buyGames(
              account.address,
              amount,
              receipt.transactionHash
            );
            break; // Exit loop if successful
          } catch (error) {
            retryCount++;
            if (retryCount === maxRetries) {
              throw error;
            }
            await new Promise((resolve) =>
              setTimeout(resolve, 1000 * retryCount)
            );
          }
        }

        // 9. Update state after successful backend update
        await Promise.all([
          dispatch("fetchGamesLeft"),
          dispatch("fetchUserBalance"),
        ]);

        toast.success(`Successfully purchased ${amount} games!`);
        return true;
      } catch (error) {
        console.error("❌ buyMoreGames process failed:", error);
        if (error.message?.includes("User denied")) {
          toast.error("Transaction cancelled");
        } else {
          toast.error("Failed to buy games. Please try again.");
        }
        return false;
      }
    },
    startPolling({ state, commit, dispatch }) {
      console.log(" Starting polling");

      if (state.pollInterval) {
        clearInterval(state.pollInterval);
      }

      if (!state.isPolling) {
        const getPollingInterval = () => {
          if (state.isTransitioningToClaimTime || state.isTransitioningToFundingState) {
            return 30000;
          }
          switch (state.gameState) {
            case 2:
              return 30000;
            default:
              return 15000;
          }
        };

        const pollingInterval = getPollingInterval();
        console.log(`📡 Setting polling interval to ${pollingInterval / 1000}s`);

        commit("SET_IS_POLLING", true);
        const pollInterval = setInterval(async () => {
          console.log(" Polling tick");
          try {
            const newGameState = await dispatch("fetchGameState");
            await dispatch("fetchPrizePool");

            // Handle state transitions during polling
            if (newGameState !== state.gameState) {
              console.log(`State changed from ${state.gameState} to ${newGameState}`);
              
              // If we detect game state and there's no countdown running
              if (newGameState === 1 && !state.countdown.interval) {
                dispatch("clearScoreSubmissions");
                commit("CLEAR_COUNTDOWN");
                
                dispatch("startCountdown", {
                  type: "game",
                  duration: GAME_DURATION
                });

                await Promise.all([
                  dispatch("fetchPrizePool"),
                  dispatch("fetchGamesLeft"),
                  dispatch("fetchGameEndTime")
                ]);
              }
              
              // Update the state after handling transitions
              commit("SET_GAME_STATE", newGameState);
            }
          } catch (error) {
            console.error("Polling error:", error);
            clearInterval(pollInterval);
            commit("SET_IS_POLLING", false);
          }
        }, pollingInterval);

        commit("SET_POLL_INTERVAL", pollInterval);
      }
    },
    async fetchFlappyPrice({ commit }) {
      try {
        const price = await apiService.getFlappyPrice();
        commit("SET_FLAPPY_PRICE", price);
      } catch (error) {
        console.error("Failed to fetch Flappy price:", error);
        toast.error("Failed to fetch Flappy price");
      }
    },
    clearScoreSubmissions() {
      localStorage.removeItem("scoreSubmissions");
    }
  },
  watch: {
    'account.address': {
      handler: async function(newAddress, oldAddress) {
        if (newAddress !== oldAddress) {
          console.log("🔄 Wallet switched:", { from: oldAddress, to: newAddress });
          
          // Clear previous wallet's state
          this.commit("CLEAR_USER_DATA");
          
          // Reinitialize with new wallet
          if (newAddress) {
            try {
              await this.dispatch("initializeContracts");
              await this.dispatch("fetchInitialData");
              await this.dispatch("checkEntryFeeStatus");
              
              // Check stored submissions for new wallet
              const storedSubmissions = localStorage.getItem("scoreSubmissions");
              if (storedSubmissions) {
                const submissions = JSON.parse(storedSubmissions);
                const currentWalletSubmission = submissions.find(submission => 
                  submission.address.toLowerCase() === newAddress.toLowerCase()
                );
                
                if (currentWalletSubmission && currentWalletSubmission.hasSubmitted) {
                  this.commit("SET_SCORE_SUBMITTED", true);
                } else {
                  this.commit("SET_SCORE_SUBMITTED", false);
                }
              }

              // Check stored claim states if in claim period
              if (this.state.gameState === 2) {
                const storedClaims = localStorage.getItem("claimStates");
                if (storedClaims) {
                  const claims = JSON.parse(storedClaims);
                  const currentWalletClaim = claims.find(claim => 
                    claim.address.toLowerCase() === newAddress.toLowerCase()
                  );
                  
                  if (currentWalletClaim) {
                    this.commit("SET_HAS_CLAIMED", currentWalletClaim.hasClaimed);
                  } else {
                    this.commit("SET_HAS_CLAIMED", false);
                  }
                }
              }
            } catch (error) {
              console.error("Error reinitializing with new wallet:", error);
              toast.error("Failed to initialize with new wallet. Please refresh the page.");
            }
          }
        }
      },
      immediate: true
    }
  }
});

export default store;

// Helper function to check blockchain
async function checkBlockchainEntryFee(context) {
  const { state, commit, dispatch } = context;

  try {
    let txHash = state.lastEntryFeeTx;
    if (!txHash) {
      txHash = await dispatch("fetchRecentTransactions");
    }

    if (!txHash) {
      commit("SET_ENTRY_FEE_STATUS", false);
      return;
    }

    const receipt = await state.web3.eth.getTransactionReceipt(txHash);
    if (receipt && receipt.status) {
      const txBlock = await state.web3.eth.getBlock(receipt.blockNumber);
      const txTimestamp = parseInt(txBlock.timestamp);
      const currentGameStartTime = await state.GameContract.methods
        .gameStartTime()
        .call();

      // Get existing entry fee data array
      let existingEntryFees = [];
      const storedEntryFees = localStorage.getItem("lastEntryFeeData");
      if (storedEntryFees) {
        existingEntryFees = JSON.parse(storedEntryFees);
        if (!Array.isArray(existingEntryFees)) {
          existingEntryFees = [];
        }
      }

      // Add or update entry fee data for current wallet
      const walletIndex = existingEntryFees.findIndex(entry => 
        entry.address.toLowerCase() === account.address.toLowerCase()
      );

      const newEntryFeeData = {
        timestamp: txTimestamp,
        address: account.address,
        gameStartTime: currentGameStartTime.toString()
      };

      if (walletIndex >= 0) {
        existingEntryFees[walletIndex] = newEntryFeeData;
      } else {
        existingEntryFees.push(newEntryFeeData);
      }

      localStorage.setItem("lastEntryFeeData", JSON.stringify(existingEntryFees));

      if (isWithinPastHours(txTimestamp, 1)) {
        commit("SET_ENTRY_FEE_STATUS", true);
        commit("SET_GAME_VISIBILITY", true);
        return;
      }
    }

    commit("SET_ENTRY_FEE_STATUS", false);
  } catch (error) {
    console.error("Failed to check entry fee status:", error);
    toast.error("Failed to check entry fee status.");
    commit("SET_ENTRY_FEE_STATUS", false);
  }
}
