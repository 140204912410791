<!-- Header.vue -->
<template>
  <div v-if="isInitialized">
    <div>
      <h1 class="text-xl font-sans text-white p-5 text-center">Games left</h1>
    </div>
    <div class="flex justify-center mb-5">
      <div class="relative w-48 h-48">
        <svg viewBox="0 0 100 100" class="w-full h-full">
          <defs>
            <linearGradient id="progressGradient" x1="0%" y1="0%" x2="100%" y2="0%">
              <stop offset="0%" style="stop-color: #60a5fa" />
              <stop offset="50%" style="stop-color: #93c5fd" />
              <stop offset="100%" style="stop-color: #60a5fa" />
            </linearGradient>
            <filter id="glow">
              <feGaussianBlur stdDeviation="2" result="coloredBlur"/>
              <feMerge>
                <feMergeNode in="coloredBlur"/>
                <feMergeNode in="SourceGraphic"/>
              </feMerge>
            </filter>
          </defs>
          <!-- Background circle -->
          <circle
            r="35"
            cx="50"
            cy="50"
            fill="transparent"
            stroke="#1F2937"
            stroke-width="8"
            class="opacity-30"
          />
          <!-- Progress circle with gradient -->
          <circle
            r="35"
            cx="50"
            cy="50"
            fill="transparent"
            :stroke-dasharray="`${calculateProgress} ${fullCircle}`"
            stroke="url(#progressGradient)"
            stroke-width="8"
            class="transition-all duration-500"
            transform="rotate(-90 50 50)"
            filter="url(#glow)"
          />
        </svg>
        <!-- Centered text -->
        <div class="absolute inset-0 flex items-center justify-center">
          <span class="text-base font-bold text-white glow-text">
            {{ gamesLeft }}/{{ maxGames }}
          </span>
        </div>
      </div>
    </div>
  </div>
  <div v-else class="text-center">
    <span class="text-white">Loading...</span>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "GamesLeft",
  computed: {
    ...mapState({
      gamesLeft: (state) => state.gamesLeft,
      web3: (state) => state.web3,
      web3Address: (state) => state.web3Address,
      GameContract: (state) => state.GameContract,
      totalGamesForRound: (state) => state.totalGamesForRound || 10,
    }),
    isInitialized() {
      return this.web3 && this.web3Address && this.GameContract;
    },
    fullCircle() {
      return 2 * Math.PI * 35;
    },
    maxGames() {
      return this.totalGamesForRound;
    },
    calculateProgress() {
      return (this.gamesLeft / this.totalGamesForRound) * this.fullCircle;
    },
  },
  // Any header-specific logic or data properties can go here
};
</script>

<style scoped>
.glow-text {
  text-shadow: 0 0 10px rgba(96, 165, 250, 0.7);
}
</style>
