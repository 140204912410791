<template>
  <div class="progress-bar-container mb-6">
    <div v-if="isLoading" class="loading-container">
      <span class="loading-text">Loading</span>
    </div>
    <div v-else class="progress-wrapper">
      <svg viewBox="0 0 100 8" class="w-full h-14" preserveAspectRatio="none">
        <!-- Dark background bar -->
        <rect
          x="0"
          y="2"
          width="100%"
          height="8"
          fill="#1F2937"
          class="background-bar"
        />
        <!-- Countdown progress bar -->
        <rect
          x="0"
          y="2"
          :width="`${progressWidth}%`"
          height="8"
          fill="url(#progressGradient)"
          class="progress-bar"
        />
        <!-- Time display -->
        <text
          x="50%"
          y="6"
          text-anchor="middle"
          class="time-text"
          fill="#ffffff"
          filter="url(#textGlow)"
        >{{ stateText }} {{ displayText }}</text>
        <!-- Gradient definitions -->
        <defs>
          <linearGradient id="progressGradient" x1="0%" y1="0%" x2="100%" y2="0%">
            <stop offset="0%" style="stop-color: #60a5fa" />
            <stop offset="50%" style="stop-color: #93c5fd" />
            <stop offset="100%" style="stop-color: #60a5fa" />
          </linearGradient>
          <!-- Add orange glow filter -->
          <filter id="textGlow">
            <feGaussianBlur stdDeviation="0.6" result="blur" />
            <feFlood flood-color="#fb923c" flood-opacity="0.5" result="color"/>
            <feComposite in="color" in2="blur" operator="in" result="glow"/>
            <feMerge>
              <feMergeNode in="glow"/>
              <feMergeNode in="glow"/>
              <feMergeNode in="SourceGraphic"/>
            </feMerge>
          </filter>
        </defs>
      </svg>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "ProgressBarWithTimeDisplay",
  data() {
    return {
      isLoading: true,
    };
  },
  computed: {
    ...mapState({
      gameState: state => state.gameState,
      countdown: state => state.countdown,
      isTransitioningToClaimTime: state => state.isTransitioningToClaimTime,
      isTransitioningToFundingState: state => state.isTransitioningToFundingState
    }),
    displayText() {
      return this.countdown?.displayText || "0:00";
    },
    progressWidth() {
      // Convert circumference value (2 * PI * 35) to percentage (0-100)
      const circumference = 2 * Math.PI * 35;
      const progress = this.countdown?.progressValue || 0;
      const percentage = (progress / circumference) * 100;
      // Invert the percentage to show remaining time instead of elapsed time
      return percentage;
    },
    stateText() {
      if (this.gameState === 0) return "";
      if (this.isTransitioningToClaimTime) return "Finalizing Round:";
      if (this.isTransitioningToFundingState) return "New Funding Starts in:";
      if (this.gameState === 2) return "Claim Period Ends in:";
      if (this.countdown?.type === 'funding') return "Funding Period Ends in:";
      return "Round Ends in:";
    }
  },
  async mounted() {
    this.$store.dispatch('initializeCountdown');
    this.isLoading = false;
  }
};
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap');
</style>

<style scoped>
.progress-bar-container {
  width: 100%;
  max-height: 34px;
  position: relative;
  padding: 0;
}

.progress-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
}

.background-bar {
  opacity: 0.8;
}

.progress-bar {
  transition: width 0.5s linear;
}

.time-text {
  font-family: 'Press Start 2P', monospace;
  font-size: 1.5px;
  letter-spacing: 0px;
  dominant-baseline: auto;
  text-transform: uppercase;
  font-weight: normal;
  text-shadow: 
    0 0 4px rgba(96, 165, 250, 0.7),
    0 0 8px rgba(96, 165, 250, 0.5);
}

/* Loading animation styles */
.loading-container {
  height: 6px;
  background: #1F2937;
  overflow: hidden;
  position: relative;
}

.loading-container::after {
  content: '';
  position: absolute;
  top: 0;
  left: -50%;
  width: 50%;
  height: 100%;
  background: linear-gradient(
    90deg,
    transparent,
    rgba(96, 165, 250, 0.5),
    transparent
  );
  animation: loading 1.5s infinite;
}

@keyframes loading {
  0% {
    left: -50%;
  }
  100% {
    left: 150%;
  }
}
</style>