export async function pollTransactionReceipt(txHash, alchemyUrl) {
    try {
      const pollInterval = 2000; // 2 seconds
      let attempt = 0;
      const maxAttempts = 30; // Adjust based on expected network latency
      await new Promise(resolve => setTimeout(resolve, 2500));
      while (attempt < maxAttempts) {
        const response = await fetch(alchemyUrl, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            jsonrpc: "2.0",
            id: 1,
            method: "eth_getTransactionReceipt",
            params: [txHash],
          }),
        });
  
        const textResponse = await response.text();
        if (!response.ok || !textResponse) {
          console.info("Raw RPC response:", textResponse);
          console.error("❌ Empty or invalid response from Alchemy:", response.status, textResponse);
          throw new Error("Failed to fetch transaction receipt.");
        }
  
        const { result, error } = JSON.parse(textResponse);
  
        if (error) {
          console.error("RPC Error:", error);
          throw new Error(error.message || "Unknown RPC error");
        }
  
        if (result) {
          console.log("🎉 Receipt found:", result);
          return result; // Receipt found
        }
  
        console.log(`⏳ Polling for receipt (attempt ${++attempt}/${maxAttempts})...`);
        await new Promise((resolve) => setTimeout(resolve, pollInterval));
      }
  
      throw new Error("Transaction receipt not found after maximum attempts");
    } catch (error) {
      console.error("❌ Error polling transaction receipt:", error);
      throw new Error("Failed to fetch transaction receipt.");
    }
  }
  
  